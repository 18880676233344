import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '../common/dialog-content';
import DialogTitle from '../common/dialog-title';
import { isMobile } from 'react-device-detect';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import api from '../../../services/api';
import { format, sub, add, isFuture, set, formatISO } from 'date-fns';
import ptBr from "date-fns/locale/pt-BR";
import { Button, LinearProgress, ListItemSecondaryAction } from '@material-ui/core';
import { formatDateTimeDay } from '../../../services/date';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Statements({
  open,
  onClose,
  apiStatements="/me/statements",
  amountField="amount_user",
  defaultDate,
  setFirstDayOfMonth = true,
}) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fullscreen, setFullscreen] = useState(isMobile);
  const [dates, setDates] = useState();
  const [valueFormat] = useState(open === 'amount' ? { style: 'currency', currency: 'BRL' } : undefined);

  useEffect(() => {
    (async () => {
      if (!dates?.current) return;

      setLoading(true);
      const response = await api.get(`${apiStatements}?type=${open}&month=${formatISO(dates.current)}`);
      setData(response.data);
      setLoading(false);
    })();
  }, [dates]);

  useEffect(() => {
    handleDates(defaultDate ? defaultDate : new Date());
  }, [defaultDate]);

  const handleDates = (current) => {
    if (setFirstDayOfMonth) {
      current = set(current, {
        date: 1,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    }

    const after = add(current, { months: 1 });

    setDates({
      current: current,
      before: sub(current, { months: 1 }),
      after: isFuture(after) ? null : after,
    });
  };

  const handleBefore = () => {
    handleDates(sub(dates.current, { months: 1 }));
  };

  const handleAfter = () => {
    handleDates(add(dates.current, { months: 1 }));
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="md"
      fullScreen={fullscreen}
      fullWidth
    >
      <DialogTitle id="statements-dialog-title"
        title="Extrato"
        onClose={onClose}
        fullscreen={fullscreen}
        setFullscreen={setFullscreen}
      />
      <DialogContent dividers style={{ minHeight: 400 }}>
        {loading ? <LinearProgress /> : (
          <>
            {open === 'amount' && dates && (
              <>
                <table style={{ width: '100%' }}>
                  <tr>
                    <td style={{ width: '33%' }}><Button onClick={() => handleBefore()} variant="outlined" startIcon={<ArrowLeftIcon />}>{format(dates.before, 'MMMM/yyyy', { locale: ptBr })}</Button></td>
                    <td style={{ textAlign: 'center', width: '33%' }}><span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{format(dates.current, 'MMMM/yyyy', { locale: ptBr })}</span></td>
                    <td style={{ textAlign: 'right', width: '33%' }}>
                      {dates.after && <Button onClick={() => handleAfter()} variant="outlined" endIcon={<ArrowRightIcon />}>{format(dates.after, 'MMMM/yyyy', { locale: ptBr })}</Button>}
                    </td>
                  </tr>
                </table>
                <br />
              </>
            )}
            <List className={classes.root}>
              {data.map((statement) => {
                return (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ background: statement.approved ? 'blue' : null }}>
                        {statement.approved ? (
                          <>
                            {open === 'amount' && <MonetizationOnIcon />}
                            {open === 'points' && <CardGiftcardIcon />}
                          </>
                        ) : <HourglassEmptyIcon />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={statement.description} secondary={
                      <>
                        {statement.user_name && <div>Usuário: {statement.user_name}</div>}
                        <div>Criado: {format(new Date(statement.reference), 'dd/MM/yyyy')}</div>
                        {open === 'points' && <div>Expira em: {formatDateTimeDay(statement.points_expire)}</div>}
                        {!statement.approved && <div style={{ fontStyle: 'italic' }}>Pendente: {statement.pending_reason}</div>}
                      </>
                    } />
                    <ListItemSecondaryAction>
                      {new Intl.NumberFormat('pt-BR', valueFormat).format(
                        open === 'amount' ? statement[amountField] : statement.points
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>

            {data.length === 0 && (
                <div style={{padding: 20, textAlign: 'center'}}>Nenhum registro encontrato para este mês.</div>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
